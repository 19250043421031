import { AvatarList } from 'simple-core-ui'
import React from 'react'
import { format } from 'date-fns'
import AIStarsIcon from './AiIcon'
import WarningIcon from './WarningIcon'
import s from '../styles/AIChat.scss'

interface MessageProps {
  text: React.ReactNode
  role: 'user' | 'system' | 'assistant'
  message_type: string
  created_date: string
}

const Message: React.FC<MessageProps> = ({ text, role, message_type, created_date }) => {
  const user = window.credentials.user

  return (
    <div className={[s['message'], role && s[role]].filter(Boolean).join(' ')}>
      <div className={s['message-bubble']}>
        {role === 'user' && (
          <AvatarList
            size="md"
            className={s['avatar-list']}
            limit={2}
            avatarStyles={{ marginLeft: 0 }}
            entries={[{ label: user.firstName + ' ' + user.lastName }]}
          />
        )}
        {role === 'assistant' || role === 'system' ? (
          <div className={s['avatar-list']}>
            <AIStarsIcon style={{ width: '25px', height: '25px' }} />
          </div>
        ) : null}
        <div>
          <div className={s['message-date']}>
            {format(new Date(created_date), "MMMM d, yyyy 'at' h:mm a")}
          </div>
          <div className={[message_type === 'error' && s['error']].filter(Boolean).join(' ')}>
            {message_type === 'error' ? <WarningIcon /> : null} {text}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Message
