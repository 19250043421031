import { useEffect, useState } from 'react'
import { makeDeleteRequest, makeGetRequest, makePostRequest } from 'utils/api'
import Message from './Message'
import MessageInput from './MessageInput'
import Sidebar from './Sidebar'
import s from '../styles/AIChat.scss'
import { useChatContext } from '../context/ChatContext'
import { IChat, IMessage } from '../types/models'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import AIStarsIcon from './AiIcon'
import { Button, ModalContainer } from 'simple-core-ui'
import swal from 'sweetalert'

const customPrompts = [
  'What is my total spend YTD?',
  'How many invoices are pending payment?',
  'What is the average invoice amount?',
  'Who are my top 5 vendors by spend?'
]

const Chat = () => {
  const [messages, setMessages] = useState<IMessage[]>([])
  const [isLoadingMessage, setIsLoadingMessage] = useState(false)
  const { id } = useParams<{ id?: string }>()
  const [selectedChat, setSelectedChat] = useState<string | undefined | null>(id || undefined)
  const [chats, setChats] = useState<IChat[]>([])
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {
    data: { user, urls },
    methods
  } = useChatContext()

  async function fetchChatList() {
    const response = await fetch(urls.chatList)
    const data = await response.json()
    setChats(data.chats)
  }

  async function fetchChatDetail() {
    const response = await makeGetRequest(`${urls.chatDetail.replace('<id>', selectedChat || '')}`)
    setMessages(response.messages)
  }

  const handleDeleteChat = async (id: string) => {
    const deleteConfirmation = await swal({
      title: 'Delete Chat',
      text: `Are you sure you want to delete this chat? This action cannot be undone.`,
      buttons: ['Cancel', 'Delete']
    })

    if (deleteConfirmation) {
      await makeDeleteRequest(`${urls.chatDetail.replace('<id>', id || '')}`)
      await fetchChatList()
    }
  }

  useEffect(() => {
    fetchChatList()
  }, [])

  useEffect(() => {
    if (selectedChat) {
      fetchChatDetail()
      navigate(`/v2/reports/aichat/${selectedChat}`)
    } else if (selectedChat === null) {
      setMessages([])
      navigate(`/v2/reports/aichat`)
    }
  }, [selectedChat])

  const handleSendMessage = async (text: string) => {
    if (text.trim()) {
      setIsLoadingMessage(true)
      const newUserMessage: IMessage = {
        text,
        role: 'user',
        is_visible: true,
        message_type: 'user_message',
        created_date: new Date().toISOString()
      }
      setMessages(prevMessages => [...prevMessages, newUserMessage])

      if (selectedChat !== null && selectedChat !== undefined) {
        newUserMessage.chat_id = selectedChat
      }
      let response

      try {
        response = await makePostRequest(urls.submitMessage, newUserMessage)
      } catch (e) {
        dispatch({
          type: 'PUSH_NOTIFICATION',
          payload: {
            title: 'Something went wrong',
            message: JSON.stringify(e),
            level: 'error'
          }
        })
        return
      }

      if (!selectedChat) {
        setSelectedChat(response.chat_id)
      } else {
        await fetchChatDetail()
      }
      setIsLoadingMessage(false)

      await fetchChatList()
    }
  }

  const handleSelectChat = (id: string) => {
    setSelectedChat(id)
  }

  const handleNewChat = () => {
    setSelectedChat(null)
  }

  return (
    <div className={s['chat-app']}>
      <Sidebar
        selectedChat={selectedChat}
        chats={chats}
        onSelectSession={handleSelectChat}
        handleNewChat={handleNewChat}
        handleDeleteChat={handleDeleteChat}
      />
      <div className={s['chat-container']}>
        {!messages.length ? (
          <div className={s['empty-state']}>
            <h2>Hi {user.firstName}</h2>
            <AIStarsIcon style={{ width: '80px' }} />
            <p>Get the answers you need just by asking AI</p>
            <section className={s['custom-prompts']}>
              {customPrompts.map((prompt, index) => (
                <article key={index} onClick={() => handleSendMessage(prompt)}>
                  {prompt}
                </article>
              ))}
            </section>
          </div>
        ) : (
          <div className={s['message-container']}>
            {messages
              .filter(m => m.is_visible)
              .map((message, index) => (
                <Message
                  key={index}
                  text={message.text}
                  created_date={message.created_date}
                  role={message.role}
                  message_type={message.message_type}
                />
              ))}
            {isLoadingMessage && (
              <Message
                key="loading"
                text={<div className={s['blinking-dot']}></div>}
                created_date={new Date().toISOString()}
                role="assistant"
                message_type="loading"
              />
            )}
            <ModalContainer
              title="Logic"
              content={
                <div>
                  <h3>Message Logic</h3>
                  <ul>
                    {messages.map((message, index) => {
                      return (
                        <li style={{ margin: '12px 0', whiteSpace: 'pre-line' }} key={index}>
                          <strong>{message.role}:</strong> {message.text}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              }
              cancelCb={() => null}
            >
              {(openModal: () => void) => {
                return (
                  <Button
                    isSecondary
                    style={{ position: 'absolute', top: 12, right: 8 }}
                    onClick={openModal}
                  >
                    View Logic
                  </Button>
                )
              }}
            </ModalContainer>
          </div>
        )}
        <MessageInput onSendMessage={handleSendMessage} />
      </div>
    </div>
  )
}

export default Chat
